import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

export interface SimpleResponse {
  status: string;
}

export interface LoginResponse {
  status: string;
  token?: string;
}

export interface InfoResponse {
  status: string;
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  country: string;
  role: string;
}

export interface ModulesResponse {
  status: string;
  modules: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public country: string;

  constructor(public http: HttpClient) {
    this.country = null;
  }

  login(credentials, country): Promise<void> {
    this.country = country;
    return new Promise<void>((resolve, reject) => {
      this.http.post<LoginResponse>(environment.apiURL[country] + '/user/login', credentials, {responseType: 'json'})
        .subscribe(data => {
            if(data.status === 'success') {
              localStorage.setItem('access_token', data.token);
              this.setFCMToken(country);
              resolve();
            }
            else {
              reject();
            }
          },
          () => reject()
        );
    });
  }

  info(): Promise<void> {
    return new Promise<void>(((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        let c = this.country;
        if(localStorage.getItem('country') !== null) {
          c = localStorage.getItem('country');
        }
        if(c !== null) {
          const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
          this.http.get<InfoResponse>(environment.apiURL[c] + '/user/info', {
            headers: authorizationHeader,
            responseType: 'json'
          })
            .subscribe(data => {
                if(data.status === 'success') {
                  for(const key in data) {
                    if(key !== 'success') {
                      localStorage.setItem(key, data[key]);
                    }
                  }
                  resolve();
                }
                else {
                  reject();
                }
              },
              () => reject()
            );
        }
        else {
          reject();
        }
      }
      else {
        reject();
      }
    }));
  }

  modules(): Promise<Array<string>> {
    return new Promise<Array<string>>(((resolve, reject) => {
      if(localStorage.getItem('access_token') !== null) {
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.get<ModulesResponse>(environment.apiURL[localStorage.getItem('country')] + '/user/modules', {
          headers: authorizationHeader,
          responseType: 'json'
        })
          .subscribe(data => {
              if(data.status === 'success') {
                resolve(data.modules);
              }
              else {
                reject();
              }
            },
            () => reject()
          );
      }
      else {
        reject();
      }
    }));
  }

  setFCMToken(country) {
    if(localStorage.getItem('access_token') !== null) {
      if(localStorage.getItem('FCMToken') !== null) {
        const token = {token: localStorage.getItem('FCMToken')};
        const authorizationHeader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this.http.post<SimpleResponse>(environment.apiURL[country] + '/user/fcm_token', token, {
          headers: authorizationHeader,
          responseType: 'json'
        })
          .subscribe(data => {
              if(data.status === 'success') {
                console.log('FCM Token stored successfully');
              }
              else {
                console.log('Unable to store FCM Token');
              }
            },
            () => console.log('Unable to store FCM Token')
          );
      }
    }
  }

  getLang(): string {
    if(localStorage.getItem('lang') !== null) {
      return localStorage.getItem('lang');
    }
    return '';
  }

  setLang(lang): void {
    localStorage.setItem('lang', lang);
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {DateService} from './date.service';

import {MainMenuComponent} from './main-menu/main-menu.component';
import {MainMenuMobileComponent} from './main-menu-mobile/main-menu-mobile.component';
import {UserMenuComponent} from './user-menu/user-menu.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {DateFilterComponent} from './date-filter/date-filter.component';
import {PageTitleComponent} from './page-title/page-title.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    TranslateModule,
    MainMenuComponent,
    MainMenuMobileComponent,
    UserMenuComponent,
    HeaderComponent,
    FooterComponent,
    DateFilterComponent,
    PageTitleComponent
  ],
  declarations: [
    MainMenuComponent,
    MainMenuMobileComponent,
    UserMenuComponent,
    HeaderComponent,
    FooterComponent,
    DateFilterComponent,
    PageTitleComponent
  ],
  providers: [
    DateService
  ]
})
export class CommonsModule {
}

import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user/user.service';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  // noinspection JSUnusedLocalSymbols
  constructor(public fcm: FCM, public plt: Platform, translate: TranslateService, private user: UserService) {
    this.plt.ready().then(() => {
      translate.addLangs(['al', 'en', 'gr', 'hr', 'it', 'si']);
      translate.setDefaultLang('en');
      const userLang = this.user.getLang();
      if(userLang !== '') {
        translate.use(userLang);
      }
      else {
        translate.use(translate.getBrowserLang());
      }

      this.fcm.onNotification().subscribe(data => {
        if(data.wasTapped) {
          console.log('Received in background');
        }
        else {
          console.log('Received in foreground');
        }
      });
      this.fcm.onTokenRefresh().subscribe(token => {
        console.log('FCM Token stored locally');
        localStorage.setItem('FCMToken', token);
      });
    });
  }
}

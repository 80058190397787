import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./tasklist/tasklist.module').then(m => m.TasklistModule)
  },
  {
    path: 'monitors',
    loadChildren: () => import('./monitors/monitors.module').then(m => m.MonitorsModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then(m => m.MapModule)
  },
  {
    path: 'upload-map',
    loadChildren: () => import('./upload-map/upload-map.module').then(m => m.UploadMapModule)
  },
  {
    path: 'processes',
    loadChildren: () => import('./processes/processes.module').then(m => m.ProcessesModule)
  },
  {
    path: 'ml-repository',
    loadChildren: () => import('./ml-repository/ml-repository.module').then(m => m.MLRepositoryModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {

  @Input() from: string;
  @Input() to: string;

  constructor() {
  }

  ngOnInit() {
  }
}

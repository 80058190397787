import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public locale: string;
  public date: Date;

  constructor() {
    this.locale = 'en-GB';
    this.date = new Date();
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

  today(): string {
    return this.date.toISOString();
  }

  formatDate(timestamp: number): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZoneName: 'short'
    };
    const formatter = new Intl.DateTimeFormat(this.locale, options);
    const date = new Date(timestamp * 1000);
    return formatter.format(date);
  }

  formatDateShort(timestamp: number): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    };
    const formatter = new Intl.DateTimeFormat(this.locale, options);
    const date = new Date(timestamp * 1000);
    return formatter.format(date);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuController} from '@ionic/angular';
import {MenuItem} from '../menu-item';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  @Input() refresh: boolean;
  public menuItems: Array<MenuItem>;

  constructor(public router: Router, public menu: MenuController) {
  }

  ngOnInit() {
    this.fetchMenuItems();
  }

  fetchMenuItems() {
    this.menuItems = [
      {title: 'profile', path: '/user/profile'}
    ];
    if(localStorage.getItem('role') !== null) {
      if(localStorage.getItem('role') === 'admin') {
        this.menuItems.push({title: 'administration', path: '/user/admin'});
      }
    }
  }

  logout() {
    localStorage.clear();
    window.location.href = '/';
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiURL: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GR: 'https://transcpew.isi.gr/gr/api',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DM: 'https://transcpew.isi.gr/demo/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IT: 'https://transcpew.isi.gr/it/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AL: 'https://transcpew.isi.gr/al/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BA: 'https://transcpew.isi.gr/ba/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ME: 'https://transcpew.isi.gr/me/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SD: 'https://transcpew.isi.gr/sd/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SI: 'https://transcpew.isi.gr/si/api/',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VA: 'https://transcpew.isi.gr/va/api/'
  },
  mlURL: 'https://transcpew.isi.gr/ml/api/',

  modules: [
    {name: 'tasks', title: 'Tasklist'},
    // {name: 'alerts', title: 'Alerts'},
    {name: 'upload-map', title: 'Upload fire danger map'},
    {name: 'map', title: 'Interactive danger map'},
    {name: 'processes', title: 'Process monitoring'},
    {name: 'monitors', title: 'Systems monitoring'},
    {name: 'ml-repository', title: 'ML Repository'}
  ],
  menuItems: [
    {title: 'dashboard', path: '/'},
    // {title: 'alerts', path: '/alerts', module: 'alerts'},
    {title: 'tasks', path: '/tasks', module: 'tasks'},
    {title: 'upload_map', path: '/upload-map', module: 'upload-map'},
    {title: 'map', path: '/map', module: 'map'},
    {title: 'monitors', path: '/monitors', module: 'monitors'},
    {title: 'processes', path: '/processes', module: 'processes'},
    {title: 'ml_repository', path: '/ml-repository', module: 'ml-repository'}
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

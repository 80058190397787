import {Component, Input, OnInit} from '@angular/core';

interface GeneralButton {
  title: string;
  link: string;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {

  @Input() title: string;
  @Input() backButton: string;
  @Input() editButton: string;
  @Input() generalButton: GeneralButton;

  constructor() {
  }

  ngOnInit() {
  }

}

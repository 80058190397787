import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public user: string;

  constructor() {
    this.user = '';
  }

  ngOnInit() {
    this.fetchUserName();
  }

  fetchUserName() {
    const firstname = localStorage.getItem('firstname');
    if(firstname !== null) {
      if(firstname !== '') {
        this.user = firstname.charAt(0);
      }
    }
    const lastname = localStorage.getItem('lastname');
    if(lastname !== null) {
      if(lastname !== '') {
        this.user += lastname.charAt(0);
      }
    }
    this.user.toUpperCase();
  }
}

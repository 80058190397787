import {Component, Input, OnInit} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {MenuItem} from '../menu-item';
import {environment} from '../../../environments/environment';
import {UserService} from '../../user/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-menu-mobile',
  templateUrl: './main-menu-mobile.component.html',
  styleUrls: ['./main-menu-mobile.component.scss'],
})
export class MainMenuMobileComponent implements OnInit {

  public menuItems: Array<MenuItem>;

  constructor(public menu: MenuController, public router: Router, public user: UserService) {
    this.menuItems = [];
  }

  ngOnInit() {
    this.fetchMenuItems();
  }

  // noinspection DuplicatedCode
  fetchMenuItems() {
    this.menuItems = [];
    if(localStorage.getItem('menu') !== null) {
      this.menuItems.push(...JSON.parse(localStorage.getItem('menu')));
    }
    else {
      this.user.modules().then(modules => {
        for(const menuItem of environment.menuItems) {
          if(menuItem.hasOwnProperty('module')) {
            if(modules.includes(menuItem.module)) {
              this.menuItems.push(menuItem);
            }
          }
          else {
            this.menuItems.push(menuItem);
          }
        }
        localStorage.setItem('menu', JSON.stringify(this.menuItems));
      }).catch(() => {
        localStorage.clear();
        this.router.navigate(['/login']);
      });
    }
  }
}
